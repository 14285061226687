<template>
  <div class="row">
    <div class="col-md-12 margin-bottom">
      <h3 class="font-secondary text-center">
        {{ $t("string.searchProjectByLandNumberOrScanQrCode") }}
      </h3>
    </div>
    <div class="col-md-6 col-md-offset-3">
      <div class="input-group">
        <input
          type="text"
          v-model="land_unique_number"
          :placeholder="$t('string.inputLandNumber')"
          class="form-control"
        >
        <span
          class="input-group-addon pointer"
          @click="search"
        >
          <i class="fa fa-search" /> {{ $t("string.search") }}
        </span>
        <span
          class="input-group-addon pointer"
          @click="turnOnCamera"
          data-toggle="modal"
          data-target="#myModal"
        >
          {{ $t("string.scanQrCode") }}
        </span>
      </div>
    </div>

    <div
      class="modal fade"
      id="myModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div
        class="modal-dialog"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              ref="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4
              class="modal-title"
              id="myModalLabel"
            >
              {{ $t("string.scanQrCode") }}
            </h4>
          </div>
          <div class="modal-body">
            <div class="qr-scanner">
              <qrcode-stream
                @decode="onDecode"
                :camera="camera"
                @init="onInit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UnderDevelopment from '@/components/UnderDevelopment'

export default {
  name: "Search",
  components: { UnderDevelopment },
  metaInfo() {
    return {
      title: this.$t("menu.search"),
      titleTemplate: "%s | " + process.env.VUE_APP_NAME,
    }
  },
  data() {
    return {
      project: null,
      land_unique_number: "",
      camera: "off",
      isLoading: false,
    }
  },
  methods: {
    onDecode(decodedString) {
      const qr_code = decodedString.split("/")[6]
      this.turnOffCamera()
      this.$refs.close.click()
      if (qr_code) {
        this.land_unique_number = qr_code
        this.searchProject()
      }
    },
    async onInit(promise) {
      this.$isLoading(true)
      try {
        // eslint-disable-next-line no-unused-vars
        const { capabilities } = await promise
      } catch (error) {
        this.onResponseError(error.name)
      } finally {
        this.$isLoading(false)
      }
    },
    turnOnCamera() {
      this.camera = "auto"
    },
    turnOffCamera() {
      this.camera = "off"
    },
    search() {
      if (
        this.land_unique_number === "" ||
        this.land_unique_number === undefined
      ) {
        return 0
      }
      this.searchProject()
    },
    searchProject() {
      this.isLoading = true
      this.$isLoading(this.isLoading)
      this.$axios
        .post(process.env.VUE_APP_API + "/api/gdc/project/search", {
          land_unique_number: this.land_unique_number,
        })
        .then(({ data }) => {
          this.project = data.data
          this.$router.push({
            name: 'show-construction',
            params: {
              project_uuid: data.data.uuid
            },
          })
        })
        .catch((error) => {
          this.onResponseError(error)
        })
        .finally(() => {
          this.isLoading = false
          this.$isLoading(this.isLoading)
        })
    },
  },
};
</script>

<style scoped>
.qr-scanner .wrapper {
  height: 420px !important;
}

.project-detail-container {
  background: #fff;
  box-shadow: 0 8px 36px #ddd;
  margin-top: 35px;
  margin-bottom: 35px;
  border-radius: 4px;
  padding: 12px;
}

.pointer {
  cursor: pointer;
}
</style>
