import Construction from "./../views/construction/Index"
import Search from "./../views/construction/Search"
import Show from "./../views/construction/Show"
import Permissions from "../permissions"

export const constructionRouters = {
  name: 'construction',
  path: 'construction',
  component: Construction,
  redirect: 'construction',
  meta: {
    permissions: [Permissions.backend.construction.manage],
    groups: 'construction',
  },
  children: [
    {
      path: '',
      name: 'search-construction',
      component: Search,
      meta: {
        permissions: [Permissions.backend.construction.search],
        groups: 'construction'
      }
    },
    {
      path: 'show/:project_uuid',
      name: 'show-construction',
      component: Show,
      meta: {
        permissions: [Permissions.backend.construction.show],
        groups: 'construction'
      }
    }
  ]
}
