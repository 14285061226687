<template>
  <div class="col-md-12 tw-pt-8">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t("string.location") }}
        </h3>
      </div>
      <GmapMap
        :center="center"
        :zoom="18"
        map-type-id="terrain"
        style="width: 100%; height: 350px"
      >
        <GmapPolyline :path="path" :options="{ strokeColor: '#ff3838' }" />
      </GmapMap>
    </div>
  </div>
</template>
<script>
export default {
  name: "LandMap",
  data() {
    return {
      path: [
        { lng: 104.917500953044, lat: 11.568938901162 },
        { lng: 104.917645398564, lat: 11.5689595826553 },
        { lng: 104.917687114538, lat: 11.5689289997403 },
        { lng: 104.91769473362, lat: 11.5688785906283 },
        { lng: 104.917608657646, lat: 11.5688655652327 },
        { lng: 104.9176137088, lat: 11.5688325832207 },
        { lng: 104.917699719293, lat: 11.5688455993734 },
        { lng: 104.917724319626, lat: 11.5686828441545 },
        { lng: 104.91739322872, lat: 11.5686327383255 },
        { lng: 104.917350069523, lat: 11.568917301639 },
        { lng: 104.917500167386, lat: 11.5689387902659 },
        { lng: 104.917500953044, lat: 11.568938901162 },
      ],
    };
  },
  computed: {
    center() {
      const totalLat = this.path.reduce((sum, point) => sum + point.lat, 0);
      const totalLng = this.path.reduce((sum, point) => sum + point.lng, 0);

      return {
        lat: totalLat / this.path.length,
        lng: totalLng / this.path.length,
      };
    },
  },
  methods: {},
};
</script>
