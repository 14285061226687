<template>
  <ProvinceList />
</template>

<script>
import ProvinceList from "./List.vue";
export default {
  name: "Province",
  components: { ProvinceList },
};
</script>

<style lang="scss" scoped></style>
