<template>
  <div class="col-md-12">
    <table class="table-bordered table-striped table-condensed">
      <tbody>
        <tr>
          <th style="width: 250px">
            {{ $t("label.certificateOfObjectIdentification") }}
          </th>
          <td>{{ land.id }}</td>
        </tr>
        <tr>
          <th>
            {{ $t("label.landLocation") }}
          </th>
          <td>{{ land.location }}</td>
        </tr>
        <tr>
          <th>
            {{ $t("label.landArea") }}
          </th>
          <td>{{ land.size }}</td>
        </tr>
        <tr>
          <th>
            {{ $t("label.ownerShip") }}
          </th>
          <td>{{ land.owner }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "LandInformationTab",
  props: {
    land: Object
  },
}
</script>

<style scoped></style>
