<template>
  <div class="row">
    <div :class="divContainerClass">
      <div class="box box-primary">
        <div
          class="box-header-custom with-border"
        >
          <div class="box-header-left">
            <h3 class="box-title">
              {{ $t('label.project') }} 
              <template v-if="$route.params.s">
                ({{ $t(`menu.provinces.${$route.params.s}`, {default: "N/A"}) }})
              </template>
            </h3>
            <template v-if="!$route.params.s || !$route.params.p">
              <ProvinceFilter @change="oTable.draw()" />
            </template>
          </div>
          <div class="box-header-right">
            <project-color-tip />
            <button
              v-if="can([Permissions.backend.provinceProject.store])"
              class="btn btn-primary btn-sm margin-r-5"
              @click="$router.push({name: 'create-province-project'})"
            >
              <i class="fa fa-plus" /> {{ $t('button.newCompany') }}
            </button>
            <template v-if="can([Permissions.backend.provinceProject.filter])">
              <button
                class="btn btn-danger btn-sm"
                @click="toggleFilter"
              >
                <i class="fa fa-filter" /> {{ $t('string.filter') }}
              </button>
            </template>
          </div>
        </div>
        <div class="box-body">
          <div>
            <table
              id="project-table"
              class="table table-hover table-full-width table-striped table-bordered"
            >
              <thead>
                <tr>
                  <th style="width: 20px;" />
                  <th>{{ $t('table.owners') }}</th>
                  <th>{{ $t('table.constructionType') }}</th>
                  <th>{{ $t('table.projectType') }}</th>
                  <th>{{ $t('table.nameInKhmer') }}</th>
                  <th>{{ $t('table.permitNumber') }}</th>
                  <th>{{ $t('table.permitDate') }}</th>
                  <th>{{ $t('table.inspectionTeam') }}</th>
                  <th>{{ $t('table.action') }}</th>
                </tr>
              </thead>
              <tbody />
            </table>
          </div>
        </div>
      </div>
    </div>
    <template v-if="showFilter">
      <div class="col-md-2 pl-md-0">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('string.filter') }}
            </h3>
            <div class="box-tools">
              <button
                v-if="can([Permissions.backend.provinceProject.filter])"
                class="btn btn-info btn-block btn-xs"
                @click="downloadFile"
              >
                <i class="fa fa-download" /> {{ $t('string.exportFile') }}
              </button>
            </div>
          </div>
          <div class="box-body">
            <div class="form-group">
              <label>{{ $t('table.permitNumber') }}</label>
              <input
                v-model="permit_number"
                :placeholder="$t('table.permitNumber')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('label.permitYear') }}</label>
              <input
                v-model="permit_number_year"
                :placeholder="$t('label.permitYear')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.permitDate') }}</label>
              <date-picker
                v-model="dateRange"
                :placeholder="$t('table.permitDate')"
                class="table-full-width"
                format="DD/MM/YYYY"
                range
                type="date"
              />
            </div>
            <div class="form-group">
              <label>{{ $t('table.projectType') }}</label>
              <select
                v-model="project_type"
                class="form-control"
                name="project_types"
              >
                <option
                  :value="null"
                  disabled
                  selected
                >
                  {{ $t('label.chooseProjectType') }}
                </option>
                <template v-for="(item, key) in project_types">
                  <option
                    :key="key"
                    :value="item.id"
                  >
                    {{ item['name_' + $i18n.locale] }}
                  </option>
                </template>
              </select>
            </div>
            <div class="form-group">
              <label>{{ $t('table.projectNameInKhmer') }}</label>
              <input
                v-model="project_name"
                :placeholder="$t('table.projectNameInKhmer')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.owners') }}</label>
              <input
                v-model="owner_name"
                :placeholder="$t('table.owners')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('label.projectSituation') }}</label>
              <select
                v-model="project_situation"
                class="form-control"
                name="project_situation"
              >
                <option
                  :value="null"
                  disabled
                  selected
                >
                  {{ $t('label.chooseProjectSituation') }}
                </option>
                <option value="new">
                  {{ $t('color_guideline.new') }}
                </option>
                <option value="permit">
                  {{ $t('color_guideline.permit') }}
                </option>
                <option value="open">
                  {{ $t('color_guideline.open') }}
                </option>
                <option value="stop">
                  {{ $t('color_guideline.stop') }}
                </option>
                <option value="pending">
                  {{ $t('color_guideline.pending') }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>{{ $t('table.is_published') }}</label>
              <select
                v-model="project_published"
                class="form-control"
                name="project_published"
              >
                <option
                  :value="null"
                  selected
                >
                  {{ $t('string.all') }}
                </option>
                <option :value="true">
                  {{ $t('table.is_published') }}
                </option>
                <option :value="false">
                  {{ $t('label.not_published') }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>{{ $t('label.is_pushed') }}</label>
              <select
                v-model="project_pushed"
                class="form-control"
                name="project_pushed"
              >
                <option
                  :value="null"
                  selected
                >
                  {{ $t('string.all') }}
                </option>
                <option value="show">
                  {{ $t('label.show') }}
                </option>
                <option value="notShow">
                  {{ $t('label.notShow') }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label>{{ $t('label.floor') }}</label>
              <vue-slider
                v-model="number_floor"
                :enable-cross="false"
                :max="200"
              />
            </div>
            <div class="form-group">
              <label>{{ $t('string.location') }}</label>
              <select
                v-model="province_id"
                class="form-control"
              >
                <option
                  :value="null"
                  disabled
                  selected
                >
                  {{ $t('string.choose_province') }}
                </option>
                <option :value="-1">
                  {{ $t('string.all') }}
                </option>
                <option
                  v-for="(province, key) in provinces"
                  :key="key"
                  :value="province.id"
                >
                  {{ province['name_' + $i18n.locale] }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <button
                    class="btn btn-primary btn-block btn-sm"
                    @click="search"
                  >
                    <i class="fa fa-search" />
                    {{ $t('button.search') }}
                  </button>
                </div>
                <div class="col-md-6 pl-lg-0">
                  <button
                    class="btn btn-default btn-block btn-sm"
                    @click="reset"
                  >
                    <i class="fas fa-redo-alt" />
                    {{ $t('button.reset') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {integrationMixins} from "./../project/mixins/integration"
import ProjectColorTip from "../../components/ProjectColorTip"
import importer from "@/mixins/importer"
import ProvinceFilter from "@/components/ProvinceFilter"
import {mapGetters} from "vuex"
import {mapFields} from "vuex-map-fields"
import Permissions from "@/permissions"

export default {
  name: 'List',
  components: {ProvinceFilter, ProjectColorTip},
  metaInfo() {
    return {
      title: this.$t('menu.projectProvince'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  mixins: [integrationMixins],
  data() {
    return {
      Permissions,
      showFilter: false,
      project_types: [],
      dateRange: [],
      number_floor: [0, 200],
      allProvinces: [],
      date_start: null,
      date_end: null,
      project_name: null,
      owner_name: null,
      permit_number: null,
      permit_number_year: null,
      project_type: null,
      oTable: null,
      project_situation: null,
      project_published: null,
      project_pushed: null,
      listAllProvinceProjects: this.can(this.$Permissions.backend.provinceProject.listAll)
    }
  },
  computed: {
    divContainerClass() {
      return this.showFilter ? 'col-md-10' : 'col-md-12'
    },
    ...mapGetters('user', {
      userProvinces: 'provinces'
    }),
    provinces() {
      if (this.listAllProvinceProjects) {
        return this.allProvinces
      }
      if (this.userProvinces && Array.isArray(this.userProvinces)) {
        return this.userProvinces
      }
      return []
    },
    ...mapFields('user', {
      province_id: 'province_id'
    })
  },
  watch: {
    '$i18n.locale' () {
      this.reInitDataTable()
    },
    '$route.params.s'() {
      this.reInitDataTable()
    }
  },
  mounted() {
    this.oTable = this.initDataTable()
    this.enabledAction()
    this.getProjectTypes()
    this.getProvinces()
  },
  beforeDestroy() {
    this.oTable.clear()
    this.oTable.destroy()
  },
  methods: {
    reInitDataTable() {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.initDataTable()
      this.oTable.draw(true)
    },
    reset() {
      this.dateRange = []
      this.date_start = null
      this.date_end = null
      this.project_name = null
      this.owner_name = null
      this.permit_number = null
      this.project_type = null
      this.number_floor = [0, 200]
      this.province_id = null
      this.project_situation = null
      this.project_published = null
      this.project_pushed = null
      this.permit_number_year = null
      this.oTable.draw(true)
    },

    search() {
      if (this.dateRange.length > 0) {
        if (this.dateRange[0] && this.dateRange[1]) {
          this.date_start = this.$moment(this.dateRange[0]).locale('en').format('YYYY-MM-DD')
          this.date_end = this.$moment(this.dateRange[1]).locale('en').format('YYYY-MM-DD')
        } else {
          this.date_start = null
          this.date_end = null
        }
      }
      this.oTable.draw(true)
    },

    downloadFile() {
      this.date_start = null
      this.date_end = null

      if (this.dateRange && Array.isArray(this.dateRange) && this.dateRange.length > 0) {
        if (this.dateRange[0] && this.dateRange[1]) {
          if (this.$moment(this.dateRange[0]).isValid() && this.$moment(this.dateRange[1]).isValid()) {
            this.date_start = this.$moment(this.dateRange[0]).locale('en').format('YYYY-MM-DD')
            this.date_end = this.$moment(this.dateRange[1]).locale('en').format('YYYY-MM-DD')
          }
        }
      }

      let provinceId = this.province_id
      if (this.$route.params.p) {
        provinceId = parseInt(this.$route.params.p)
      }

      const config = {
        $axios: this.$axios,
        url: `${process.env.VUE_APP_API}/api/backend/project/export-projects`,
        params: {
          pn: this.permit_number,
          pny: this.permit_number_year,
          ds: this.date_start,
          de: this.date_end,
          pt: this.project_type,
          pname: this.project_name,
          oname: this.owner_name,
          ps: this.project_situation,
          pp: this.project_published,
          ppush: this.project_pushed,
          nf: this.number_floor,
          pi: provinceId,
          plevel: true,
        },
        filename: 'project.xlsx'
      }

      this.$isLoading(true)
      importer(config)
        .then(() => {
          this.showToastr()
        })
        .catch((error) => {
          this.onResponseError(error)
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    initDataTable() {
      let provinceId = this.province_id
      if (this.$route.params.p) {
        provinceId = parseInt(this.$route.params.p)
      }

      let self = this
      return $('#project-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: process.env.VUE_APP_API + '/api/backend/project/datatable',
          data: (d) => {
            d.edit = self.$t('label.edit')
            d.show = self.$t('label.show')
            d.delete = self.$t('label.delete')
            d.sync = self.$t('string.syncProject')
            d.pushblish = self.$t('label.publishedProject')
            d.unpushblish = self.$t('label.unpublishedProject')
            d.date_start = self.date_start
            d.date_end = self.date_end
            d.project_name = self.project_name
            d.owner_name = self.owner_name
            d.permit_number = self.permit_number
            d.project_type = self.project_type
            d.na = self.$t('string.na')
            d.is_province_data_level = true
            d.number_floor = this.number_floor
            d.province_id = provinceId,
            d.project_situation = this.project_situation
            d.project_published = this.project_published
            d.project_pushed = this.project_pushed
            d.permit_number_year = this.permit_number_year
          },
          error: (xhr) => {
            self.onResponseError(xhr)
          }
        },
        columns: [
          {
            data: 'circle',
            name: 'circle',
            orderable: false,
          },
          {
            data: 'owners',
            name: 'owners',
            orderable: false,
            title: self.$t('table.owners'),
          },
          {
            data: 'construction_type.name_en',
            name: 'constructionType.name_en',
            title: self.$t('table.constructionType'),
            render: (data, type, row) => {
              if (row['construction_type'] !== null) {
                return `${row['construction_type']['name_' + self.$i18n.locale]}`
              }
              return self.$t('string.na')
            }
          },
          {
            data: 'project_type.name_en',
            name: 'projectType.name_en',
            title: self.$t('table.projectType'),
            render: (data, type, row) => {
              if (row['project_type'] !== null) {
                return `${row['project_type']['name_' + self.$i18n.locale]}`
              }
              return self.$t('string.na')
            }
          },
          {
            data: 'name_km',
            name: 'name_km',
            width: "20%",
            title: self.$t('table.projectNameInKhmer'),
            orderable: false,
          },
          {
            data: 'permit_number',
            name: 'permit_number',
            title: self.$t('table.permitNumber'),
            render: (data) => {
              if (data !== null) {
                return data
              }
              return this.$t('string.na')
            },
          },
          {
            data: 'permit_date',
            name: 'permit_date',
            title: self.$t('table.permitDate'),
            render: (data) => {
              if (data !== null) {
                return this.$moment(data).locale('en').format('DD/MM/YYYY')
              }
              return this.$t('string.na')
            },
          },
          {
            data: 'inspection_team',
            name: 'inspection_team',
            searchable: false,
            orderable: false,
            title: self.$t('table.inspectionTeam'),
            render: () => {
              return self.$t('string.na')
            }
          },
          {
            data: 'action',
            name: 'action',
            width: '130px',
            orderable: false,
            searchable: false,
            title: self.$t('table.action')
          }
        ],
        language: {
          url: self.getLocale,
        },
        order: [
          [6, 'desc']
        ],
        drawCallback () {
          $('[data-toggle="tooltip"]').tooltip()
        }
      })
    },
    getProvinces() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/location/get-location')
        .then(({data}) => {
          if (data.data) {
            this.allProvinces = data.data
          } else {
            this.allProvinces = []
          }
        })
    },
    enabledAction() {
      let self = this
      this.clearEventHandler([
        '.btn-edit',
        '.btn-show',
        '.btn-trash',
        '.btn-sync',
        '.btn-published',
        '.btn-unpublished',
        '.sidebar-toggle',
      ])
      $(document).on('click', '.sidebar-toggle', function () {
        self.oTable.columns.adjust().draw()
      })
      $(document).on('click', '.btn-sync', function () {
        self.createOrUpdateProject($(this).attr('data-uuid'))
      })
      $(document).on('click', '.btn-published', function () {
        self.publishedProject($(this).attr('data-uuid'))
      })
      $(document).on('click', '.btn-unpublished', function () {
        self.unpublishedProject($(this).attr('data-uuid'))
      })
      $(document).on('click', '.btn-edit', function () {
        self.$router.push({
          name: 'edit-province-project',
          params: {
            project_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-show', function () {
        self.$router.push({
          name: 'show-province-project',
          params: {
            project_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-trash', function () {
        self.deleteProject($(this).attr('data-uuid'))
      })
    },
    toggleFilter() {
      this.showFilter = !this.showFilter
      this.oTable.columns.adjust().draw()
    },
    getProjectTypes() {
      this.$axios.post(this.$base_api + '/api/backend/project-type/get-project-types')
        .then((response) => {
          this.project_types = response.data.data
        })
        .catch((error) => {
          this.onResponseError(error)
        })
    },
    deleteProject(uuid) {
      let self = this
      this.$swal.fire({
        title: self.i18nSwalTitle,
        text: self.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: self.i18nSwalNo,
        confirmButtonText: self.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/project/delete', {
            project_uuid: uuid
          }).then(() => {
            this.showSwalSuccess()
            this.oTable.draw(true)
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
