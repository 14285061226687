<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.occupancy') }}
      </h3>
      <div class="box-tools">
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <div class="form-horizontal">
        <div class="row">
          <div class="col-md-6">
            <div class="col-sm-offset-3 col-sm-9">
              <h4 class="main-title">
                {{ $t('string.aboutCertificateOfOccupancy') }}
              </h4>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div
              class="form-group row"
              :class="getSelectedProjectValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">{{ $t('string.searchProject') }}</label>
              <div class="col-sm-9">
                <multiselect
                  v-model="selected_project"
                  :options="projects"
                  :disabled="$route.name === 'edit-certificate-occupancy'"
                  track-by="id"
                  :internal-search="false"
                  :placeholder="$t('string.searchProject')"
                  label="label"
                  array-objects
                  :select-label="$t('string.press_enter_to_select')"
                  :deselect-label="$t('string.press_enter_to_remove')"
                  @search-change="searchProject"
                >
                  <template slot="noResult">
                    {{ $t('string.no_elements_found_consider_changing_the_search_query') }}
                  </template>
                  <template slot="noOptions">
                    {{ $t('string.list_is_empty') }}
                  </template>
                </multiselect>
                <template v-if="getSelectedProjectValidation.required">
                  <span class="label-error">{{ getSelectedProjectValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="permitNumberValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">{{ $t('string.buildingPermitStr') }}</label>
              <div class="col-sm-9">
                <div class="input-group">
                  <input
                    v-model="occupancy.building_permit_number"
                    type="text"
                    :placeholder="$t('string.buildingPermitNumberStr')"
                    class="form-control"
                  >
                  <span class="input-group-addon">/</span>
                  <input
                    v-model="occupancy.suffix_building_permit_number"
                    type="text"
                    :placeholder="$t('string.permit_number_suffix')"
                    class="form-control"
                  >
                </div>
                <template v-if="permitNumberValidation.required">
                  <span class="label-error">{{ permitNumberValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="permitDateValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">{{ $t('string.permitDateStr') }}</label>
              <div class="col-sm-9">
                <date-picker
                  v-model="occupancy.building_permit_date"
                  format="DD/MM/YYYY"
                  class="table-full-width"
                  :lang="datepickerLocale[$i18n.locale].lang"
                  :placeholder="$t('string.permitDateStr')"
                />
                <template v-if="permitDateValidation.required">
                  <span class="label-error">{{ permitDateValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group row">
              <label class="control-label col-sm-3">{{ $t('string.planEditingPermitNumber') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.plan_editing_permit_number"
                  type="text"
                  :placeholder="$t('string.planEditingPermitNumber')"
                  class="form-control"
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group row">
              <label class="control-label col-sm-3">{{ $t('string.planEditingPermitDate') }}</label>
              <div class="col-sm-9">
                <date-picker
                  v-model="occupancy.plan_editing_permit_date"
                  format="DD/MM/YYYY"
                  class="table-full-width"
                  :lang="datepickerLocale[$i18n.locale].lang"
                  :placeholder="$t('string.planEditingPermitDate')"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="constructionOnTheLandParcelValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">{{ $t('string.constructionOnTheLandParcel') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.construction_on_the_land_parcel"
                  type="text"
                  class="form-control"
                >
                <template v-if="constructionOnTheLandParcelValidation.required">
                  <span class="label-error">{{ constructionOnTheLandParcelValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="openConstructionValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">{{ $t('string.constructionSiteOpeningPermit') }}</label>
              <div class="col-sm-9">
                <div class="input-group tw-flex">
                  <select
                    v-model="occupancy.construction_letter_id"
                    class="form-control"
                    :disabled="selected_project===null"
                  >
                    <option
                      :value="null"
                      disabled
                      selected
                    >
                      {{ $t('string.constructionOnTheLandParcel') }}
                    </option>
                    <template v-if="open_constructions && Array.isArray(open_constructions) && open_constructions.length>0">
                      <option
                        v-for="(item, key) in open_constructions"
                        :key="key"
                        :value="item.id"
                      >
                        {{ item.label || 'គ្មាន' }}
                      </option>
                    </template>
                  </select>
                  <span
                    class="input-group-addon tw-flex tw-items-center"
                  >/</span>
                  <input
                    v-model="occupancy.construction_site_opening_permit_number_suffix"
                    type="text"
                    class="form-control"
                    :placeholder="$t('string.construction_site_opening_permit_suffix')"
                  >
                </div>
                <template v-if="openConstructionValidation.required">
                  <span class="label-error">{{ openConstructionValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="addressInKhmerValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">{{ $t('string.addressKm') }}</label>
              <div class="col-sm-9">
                <textarea
                  v-model="occupancy.address_km"
                  class="form-control"
                  :placeholder="$t('string.addressKm')"
                  rows="3"
                />
                <template v-if="addressInKhmerValidation.required">
                  <span class="label-error">{{ addressInKhmerValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="addressInEnglishValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">{{ $t('string.addressEn') }}</label>
              <div class="col-sm-9">
                <textarea
                  v-model="occupancy.address_en"
                  class="form-control"
                  :placeholder="$t('string.addressEn')"
                  rows="3"
                />
                <template v-if="addressInEnglishValidation.required">
                  <span class="label-error">{{ addressInEnglishValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="asBuildDrawingNumberValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">{{ $t('string.asBuiltDrawingNumber') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.as_build_drawing_number"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.asBuiltDrawingNumber')"
                >
                <template v-if="asBuildDrawingNumberValidation.required">
                  <span class="label-error">{{ asBuildDrawingNumberValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="asBuildDrawingDateValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">{{ $t('string.asBuiltDrawingDate') }}</label>
              <div class="col-sm-9">
                <date-picker
                  v-model="occupancy.as_build_drawing_date"
                  format="DD/MM/YYYY"
                  class="table-full-width"
                  :lang="datepickerLocale[$i18n.locale].lang"
                  :placeholder="$t('string.asBuiltDrawingDate')"
                />
                <template v-if="asBuildDrawingDateValidation.required">
                  <span class="label-error">{{ asBuildDrawingDateValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="constructionOwnerKmValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">{{ $t('string.constructionOwnerKm') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.construction_owner_km"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.constructionOwnerKm')"
                >
                <template v-if="constructionOwnerKmValidation.required">
                  <span class="label-error">{{ constructionOwnerKmValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="constructionOwnerEnValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">{{ $t('string.constructionOwnerEn') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.construction_owner_en"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.constructionOwnerEn')"
                >
                <template v-if="constructionOwnerEnValidation.required">
                  <span class="label-error">{{ constructionOwnerEnValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="certificateOfComplianceNumberValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">{{ $t('string.certificateOfCompliance') }}</label>
              <div class="col-sm-9">
                <div class="input-group">
                  <input
                    v-model="occupancy.compliance_number"
                    type="text"
                    class="form-control"
                    :placeholder="$t('string.certificateOfCompliance')"
                  >
                  <span
                    id="basic-addon2"
                    class="input-group-addon"
                  >/</span>
                  <input
                    v-model="occupancy.compliance_number_suffix"
                    type="text"
                    class="form-control"
                    :placeholder="$t('string.certificate_of_compliance_number_suffix')"
                  >
                  <span
                    id="compliance_number_suffix_en"
                    class="input-group-addon"
                  >/</span>
                  <input
                    v-model="occupancy.certificate_of_compliance_number_suffix_en"
                    type="text"
                    class="form-control"
                    :placeholder="$t('string.certificate_of_compliance_number_suffix_en')"
                  >
                </div>
                <template v-if="certificateOfComplianceNumberValidation.required">
                  <span class="label-error">{{ certificateOfComplianceNumberValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="certificateOfComplianceDateValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">{{ $t('string.certificateOfComplianceDate') }}</label>
              <div class="col-sm-9">
                <date-picker
                  v-model="occupancy.compliance_date"
                  format="DD/MM/YYYY"
                  class="table-full-width"
                  :lang="datepickerLocale[$i18n.locale].lang"
                  :placeholder="$t('string.certificateOfComplianceDate')"
                />
                <template v-if="certificateOfComplianceDateValidation.required">
                  <span class="label-error">{{ certificateOfComplianceDateValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="certificateOfComplianceByKmValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">
                {{ $t('string.certificateOfComplianceBy') }} {{ $t('string.inKm') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.compliance_by_km"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.certificateOfComplianceBy')"
                >
                <template v-if="certificateOfComplianceByKmValidation.required">
                  <span class="label-error">{{ certificateOfComplianceByKmValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="certificateOfComplianceByEnValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">
                {{ $t('string.certificateOfComplianceBy') }} {{ $t('string.inEn') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.compliance_by_en"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.certificateOfComplianceBy')"
                >
                <template v-if="certificateOfComplianceByEnValidation.required">
                  <span class="label-error">{{ certificateOfComplianceByEnValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="constructionOccupancyKmValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">{{ $t('string.constructionOccupancyKm') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.construction_occupancy_km"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.constructionOccupancyKm')"
                >
                <template v-if="constructionOccupancyKmValidation.required">
                  <span class="label-error">{{ constructionOccupancyKmValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="constructionOccupancyEnValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">{{ $t('string.constructionOccupancyEn') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.construction_occupancy_en"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.constructionOccupancyEn')"
                >
                <template v-if="constructionOccupancyEnValidation.required">
                  <span class="label-error">{{ constructionOccupancyEnValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="totalFloorAreaValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">{{ $t('string.totalFloorArea') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.total_floor_area"
                  type="number"
                  class="form-control"
                  :placeholder="$t('string.totalFloorArea')"
                >
                <template v-if="totalFloorAreaValidation.required">
                  <span class="label-error">{{ totalFloorAreaValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="col-sm-9 col-sm-offset-3">
              <h4 class="main-title">
                {{ $t('string.architecturesSection') }}
              </h4>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="architectureDesignByKmValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">{{ $t('string.architectureDesignBy') }} {{
                $t('string.inKm')
              }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.architecture_design_by_km"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.architectureDesignBy')"
                >
                <template v-if="architectureDesignByKmValidation.required">
                  <span class="label-error">{{ architectureDesignByKmValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="architectureDesignByEnValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">{{ $t('string.architectureDesignBy') }} {{
                $t('string.inEn')
              }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.architecture_design_by_en"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.architectureDesignBy')"
                >
                <template v-if="architectureDesignByEnValidation.required">
                  <span class="label-error">{{ architectureDesignByEnValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="architectureDesignReviewedByKmValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">
                {{ $t('string.architectureDesignReviewedBy') }} {{ $t('string.inKm') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.architecture_design_reviewed_by_km"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.architectureDesignReviewedBy')"
                >
                <template v-if="architectureDesignReviewedByKmValidation.required">
                  <span class="label-error">{{ architectureDesignReviewedByKmValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="architectureDesignReviewedByEnValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">
                {{ $t('string.architectureDesignReviewedBy') }} {{ $t('string.inEn') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.architecture_design_reviewed_by_en"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.architectureDesignReviewedBy')"
                >
                <template v-if="architectureDesignReviewedByEnValidation.required">
                  <span class="label-error">{{ architectureDesignReviewedByEnValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="structuralDesignKmValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">
                {{ $t('string.structuralDesign') }} {{ $t('string.inKm') }} </label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.structural_design_km"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.structuralDesign')"
                >
                <template v-if="structuralDesignKmValidation.required">
                  <span class="label-error">{{ structuralDesignKmValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="structuralDesignEnValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">
                {{ $t('string.structuralDesign') }} {{ $t('string.inEn') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.structural_design_en"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.structuralDesign')"
                >
                <template v-if="structuralDesignEnValidation.required">
                  <span class="label-error">{{ structuralDesignEnValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="structuralDesignByKmValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">
                {{ $t('string.structuralDesignBy') }} {{ $t('string.inKm') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.structural_design_by_km"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.structuralDesignBy')"
                >
                <template v-if="structuralDesignByKmValidation.required">
                  <span class="label-error">{{ structuralDesignByKmValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="structuralDesignByEnValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">
                {{ $t('string.structuralDesignBy') }} {{ $t('string.inEn') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.structural_design_by_en"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.structuralDesignBy')"
                >
                <template v-if="structuralDesignByEnValidation.required">
                  <span class="label-error">{{ structuralDesignByEnValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="structuralDesignReviewedByKmValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">
                {{ $t('string.structuralDesignReviewedBy') }} {{ $t('string.inKm') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.structural_design_reviewed_by_km"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.structuralDesignReviewedBy')"
                >
                <template v-if="structuralDesignReviewedByKmValidation.required">
                  <span class="label-error">{{ structuralDesignReviewedByKmValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="structuralDesignReviewedByEnValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">
                {{ $t('string.structuralDesignReviewedBy') }} {{ $t('string.inEn') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.structural_design_reviewed_by_en"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.structuralDesignReviewedBy')"
                >
                <template v-if="structuralDesignReviewedByEnValidation.required">
                  <span class="label-error">{{ structuralDesignReviewedByEnValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="mepSystemReviewedByKmValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">
                {{ $t('string.mepSystemReviewedBy') }} {{ $t('string.inKm') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.mep_system_reviewed_by_km"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.mepSystemReviewedBy')"
                >
                <template v-if="mepSystemReviewedByKmValidation.required">
                  <span class="label-error">{{ mepSystemReviewedByKmValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="mepSystemReviewedByEnValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">
                {{ $t('string.mepSystemReviewedBy') }} {{ $t('string.inEn') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.mep_system_reviewed_by_en"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.mepSystemReviewedBy')"
                >
                <template v-if="mepSystemReviewedByEnValidation.required">
                  <span class="label-error">{{ mepSystemReviewedByEnValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="fireSafetySystemInspectedByKmValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">
                {{ $t('string.fireSafetySystemInspectedBy') }} {{ $t('string.inKm') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.fire_safety_system_inspected_by_km"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.fireSafetySystemInspectedBy')"
                >
                <template v-if="fireSafetySystemInspectedByKmValidation.required">
                  <span class="label-error">{{ fireSafetySystemInspectedByKmValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="fireSafetySystemInspectedByEnValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">
                {{ $t('string.fireSafetySystemInspectedBy') }} {{ $t('string.inEn') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.fire_safety_system_inspected_by_en"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.fireSafetySystemInspectedBy')"
                >
                <template v-if="fireSafetySystemInspectedByEnValidation.required">
                  <span class="label-error">{{ fireSafetySystemInspectedByEnValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="buildingWorkByKmValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3  required">
                {{ $t('string.buildingWorkBy') }} {{ $t('string.inKm') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.building_work_by_km"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.buildingWorkBy')"
                >
                <template v-if="buildingWorkByKmValidation.required">
                  <span class="label-error">{{ buildingWorkByKmValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="form-group row"
              :class="buildingWorkByEnValidation.required?'has-error': null"
            >
              <label class="control-label col-sm-3 required">
                {{ $t('string.buildingWorkBy') }} {{ $t('string.inEn') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="occupancy.building_work_by_en"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.buildingWorkBy')"
                >
                <template v-if="buildingWorkByEnValidation.required">
                  <span class="label-error">{{ buildingWorkByEnValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="col-md-6">
              <div class="form-group row">
                <div class="col-sm-9 col-sm-offset-3">
                  <template v-if="!(occupancy && occupancy.hasOwnProperty('is_approved') && occupancy.is_approved)">
                    <button
                      class="btn btn-primary btn-sm margin-right-5"
                      @click="createOrUpdateOccupancy"
                    >
                      {{ $t('button.save') }}
                    </button>
                  </template>
                  <template v-if="$route.name !== 'store-certificate-occupancy'">
                    <button
                      class="btn btn-default btn-sm"
                      @click="skip"
                    >
                      {{ $t('button.skip') }}
                    </button>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {debounce} from 'debounce'
import {validationMixins} from "@/mixins/validations"
import {occupancyValidationMixins} from "@/mixins/occupancyValidations"

export default {
  name: "Store",
  metaInfo() {
    return {
      title: this.$t('string.occupancy'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  mixins: [
    validationMixins,
    occupancyValidationMixins,
  ],
  data() {
    return {
      projects: [],
      selected_project: null,

      validations: null,

      permits: [],
      land_unique_numbers: [],
      open_constructions: [],

      occupancy_uuid: null,
      occupancy: {
        building_permit_number: null,
        building_permit_date: null,
        plan_editing_permit_number: null,
        plan_editing_permit_date: null,
        construction_on_the_land_parcel: null,
        construction_letter_id: null,
        address_km: null,
        address_en: null,
        as_build_drawing_number: null,
        as_build_drawing_date: null,
        construction_owner_km: null,
        construction_owner_en: null,
        compliance_number: null,
        compliance_date: null,
        compliance_by_km: null,
        compliance_by_en: null,
        construction_occupancy_km: null,
        construction_occupancy_en: null,
        total_floor_area: null,
        architecture_design_by_km: null,
        architecture_design_by_en: null,
        architecture_design_reviewed_by_km: null,
        architecture_design_reviewed_by_en: null,
        structural_design_km: null,
        structural_design_en: null,
        structural_design_by_km: null,
        structural_design_by_en: null,
        structural_design_reviewed_by_km: null,
        structural_design_reviewed_by_en: null,
        mep_system_reviewed_by_km: null,
        mep_system_reviewed_by_en: null,
        fire_safety_system_inspected_by_km: null,
        fire_safety_system_inspected_by_en: null,
        building_work_by_km: null,
        building_work_by_en: null,
        suffix_building_permit_number: 'ដនស/អសណ',
        compliance_number_suffix: 'អសណ/នសណ. បអ',
        certificate_of_compliance_number_suffix_en: null,
        construction_site_opening_permit_number_suffix: 'អសណ/នសណ'
      },
      params: new FormData()
    }
  },
  computed: {
    api() {
      return `${this.baseUrl}/api/backend/certificate-occupancy`
    },
  },
  watch: {
    selected_project() {
      if (this.selected_project !== null) {
        this.getOpenConstructions()
        this.getConstructionPermits()
        this.getLandUniqueNumbers()
        if (this.$route.name === 'store-certificate-occupancy') {
          this.getProjectDetail()
        }
      }
    }
  },
  mounted() {
    this.searchProject()
    const { occupancy_uuid } = this.$route.params
    if (occupancy_uuid) {
      this.getCertificateOfOccupancy()
    }
  },
  methods: {
    skip() {
      this.$router.push({
        name: 'upload-certificate',
        params: {
          occupancy_uuid: this.$route.params.occupancy_uuid,
        }
      })
    },
    createOrUpdateOccupancy() {
      this.validations = null
      this.$isLoading(true)
      for (let key in this.occupancy) {
        let value = this.occupancy[key]
        if (value !== '' && value !== null && value !== undefined) {
          if (key.includes('date') && this.$moment(value).isValid()) {
            value = this.$moment(value).locale('en').format('YYYY-MM-DD')
          }
          this.params.set(key, value)
        } else {
          this.params.delete(key)
        }
      }

      if (this.selected_project !== null) {
        this.params.set('selected_project_id', this.selected_project.id)
      } else {
        this.params.delete('selected_project_id')
      }

      if (this.occupancy_uuid !== null) {
        this.params.set('occupancy_uuid', this.occupancy_uuid)
      } else {
        this.params.delete('occupancy_uuid')
      }

      this.$axios.post(`${this.api}/store`, this.params)
        .then((response) => {
          this.showToastr()
          this.$router.push({name: 'upload-certificate', params: {occupancy_uuid: response.data.data.uuid}})
        }).catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        }).finally(() => {
          this.$isLoading(false)
        })
    },
    searchProject: debounce(function (query_text = '') {
      this.$axios.post(`${this.api}/search-project`, {
        query_text: query_text
      }).then(({data}) => {
        if (data.data !== null && data.data.length > 0) {
          this.projects = data.data.map((project) => {
            let item = {
              id: project.id,
              label: ''
            }
            if (project.permit_number !== null) {
              if (project.year !== null) {
                item.label += ` (${project.permit_number}-${project.year}) `
              } else {
                item.label += ` (${project.permit_number}) `
              }
            }
            if (project.owners && Array.isArray(project.owners) && project.owners.length > 0) {
              project.owners.forEach((owner, key) => {
                item.label += owner.name_km
                if (!(key === parseInt((project.owners.length).toString()) - 1)) {
                  item.label += ', '
                }
              })
            }
            if (project.construction_type !== null) {
              item.label += " (" + project.construction_type.name_km + ")"
            }
            return item
          })
        }
      })
    }),
    async getConstructionPermits() {
      let response = await this.$axios.post(`${this.api}/get-construction-permits`, {
        selected_project_id: this.selected_project !== null ? this.selected_project.id : null,
      })
      this.permits = await response.data.data
    },
    async getLandUniqueNumbers() {
      let response = await this.$axios.post(`${this.api}/get-land-unique-numbers`, {
        selected_project_id: this.selected_project !== null ? this.selected_project.id : null,
      })
      this.occupancy.construction_on_the_land_parcel = await response.data.data
    },
    async getOpenConstructions() {
      let response = await this.$axios.post(`${this.api}/get-open-constructions`, {
        selected_project_id: this.selected_project !== null ? this.selected_project.id : null,
      })
      let open_construction_numbers = await response.data.data
      if (open_construction_numbers !== null && open_construction_numbers.length > 0) {
        this.open_constructions = open_construction_numbers.map((permit) => {
          return {
            id: permit.id,
            label: `លិខិតលេខអនុញ្ញាត ${permit.number || 'N/A'} - កាលបរិច្ឆេទ ${permit.date ? this.$moment(permit.date).format('DD/MM/YYYY') : 'N/A'}`
          }
        })
      } else {
        this.open_constructions = []
      }
    },
    async getProjectDetail() {
      let response = await this.$axios.post(`${this.api}/get-project-details`, {
        selected_project_id: this.selected_project !== null ? this.selected_project.id : null,
      })
      let project = await response.data.data
      this.occupancy.building_permit_number = project.permit_number
      if (project.building_permit_date !== null && this.$moment(project.permit_date).isValid()) {
        this.occupancy.building_permit_date = this.$moment(project.permit_date).locale('en').toDate()
      }

      const {
        full_address_km,
        full_address_en,
        construction_surface
      } = project

      this.occupancy.total_floor_area = construction_surface
      this.occupancy.address_km = full_address_km
      this.occupancy.address_en = full_address_en
      this.occupancy.is_approved = project.is_approved

      if (project.construction_type !== null) {
        this.occupancy.construction_occupancy_km = project.construction_type.name_km
        this.occupancy.construction_occupancy_en = project.construction_type.name_en
      }
      let owners_km = ""
      let owners_en = ""
      if (project.owners !== null && project.owners.length > 0) {
        project.owners.forEach((owner, key) => {
          owners_km += owner.name_km
          owners_en += owner.name_en
          if (!(key === project.owners.length - 1)) {
            owners_en += ", "
            owners_km += ", "
          }
        })
      }
      this.occupancy.construction_owner_km = owners_km
      this.occupancy.construction_owner_en = owners_en


      // open constructions
      this.open_constructions = []
      const {
        open_construction_numbers
      } = project
      if (open_construction_numbers && Array.isArray(open_construction_numbers) && open_construction_numbers.length > 0) {
        this.open_constructions = open_construction_numbers.map((permit) => {
          return {
            id: permit.id,
            label: `លិខិតលេខអនុញ្ញាត ${permit.number || 'N/A'} - កាលបរិច្ឆេទ ${permit.date ? this.$moment(permit.date).format('DD/MM/YYYY') : 'N/A'}`
          }
        })
      }

      // architecture_design_by
      this.occupancy.architecture_design_by_km = project.architecture_design_by_km
      this.occupancy.architecture_design_by_en = project.architecture_design_by_en

      // architecture_design_reviewed_by
      this.occupancy.architecture_design_reviewed_by_km = project.architecture_design_reviewed_by_km
      this.occupancy.architecture_design_reviewed_by_en = project.architecture_design_reviewed_by_en

      // structural_design
      this.occupancy.structural_design_km = project.structural_design_km
      this.occupancy.structural_design_en = project.structural_design_en

      // structural_design_by
      this.occupancy.structural_design_by_km = project.structural_design_by_km
      this.occupancy.structural_design_by_en = project.structural_design_by_en

      // structural_design_reviewed_by
      this.occupancy.structural_design_reviewed_by_km = project.structural_design_reviewed_by_km
      this.occupancy.structural_design_reviewed_by_en = project.structural_design_reviewed_by_en

      // building_work_by_km
      this.occupancy.building_work_by_km = project.building_work_by_km
      this.occupancy.building_work_by_en = project.building_work_by_en
    },
    async getCertificateOfOccupancy() {
      let response = await this.$axios.post(process.env.VUE_APP_API + '/api/backend/certificate-occupancy/show', {
        occupancy_uuid: this.$route.params.occupancy_uuid,
      })
      let {
        project,
        occupancy
      } = await response.data.data

      for (let key in occupancy) {
        let value = occupancy[key]
        if (value !== '' && value !== null && value !== undefined) {
          if (key.includes('date') && this.$moment(value).isValid()) {
            value = this.$moment(value).locale('en').toDate()
          }
          occupancy[key] = value
        } else {
          occupancy[key] = null
        }
      }

      if (!occupancy.suffix_building_permit_number) {
        occupancy.suffix_building_permit_number = 'ដនស/អសណ'
      }

      if (!occupancy.compliance_number_suffix) {
        occupancy.compliance_number_suffix = 'អសណ/នសណ. បអ'
      }

      if (!occupancy.construction_site_opening_permit_number_suffix) {
        occupancy.construction_site_opening_permit_number_suffix = 'អសណ/នសណ'
      }

      this.occupancy = occupancy

      if (project) {
        this.selected_project = {
          id: project.id,
          label: `លិខិតអនុញ្ញាត ${project.permit_number} - កាលបរិច្ឆេទ ${this.getDate(project.permit_date, false, 'DD/MM/YYYY')}`
        }

        this.building_permit_number = project.permit_number
        this.building_permit_date = null
        if (this.$moment(project.permit_date).isValid()) {
          this.building_permit_date = this.$moment(project.permit_date).locale('en').toDate()
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
