<template>
  <div class="row">
    <template v-if="!isLoading">
      <div class="col-sm-10">
        <div class="row">
          <div class="col-md-12">
            <div class="nav-tabs-custom">
              <ul
                class="nav nav-tabs"
                role="tablist"
              >
                <li class="active" role="presentation">
                  <a
                    aria-controls="land_information"
                    data-toggle="tab"
                    href="#land_information"
                    role="tab"
                  >
                    {{ $t("label.landInformation") }}
                  </a>
                </li>
              </ul>
              <div class="tab-content">
                <template>
                  <div
                    id="land_information"
                    class="tab-pane active"
                    role="tabpanel"
                  >
                    <div class="row">
                      <land-information :land="land" />
                      <construction-information :construction="construcion" />
                      <land-map />
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import LandInformation from "./includes/tabs/LandInformationTab"
import LandMap from "./includes/tabs/LandMap"
import ConstructionInformation from "./includes/tabs/ConstructionInformation"
import { mapActions, mapGetters } from "vuex"

export default {
  name: "ProjectShow",
  components: {
    LandInformation,
    LandMap,
    ConstructionInformation
  },
  metaInfo() {
    return {
      title: this.$t("string.constructionInfo"),
      titleTemplate: "%s | " + this.$t("string.appName"),
    }
  },
  data() {
    return {
      project: null,
      isLoading: true,
      land: {
        id: '120101001-2323',
        location: 'ភូមិ៖ ទី១ ឃុំ/សង្កាត់៖ ទន្លេបាសាក់ ស្រុក/ខណ្ឌ៖ ចំការមន រាជធានី/ខេត្ត/ក្រុង:៖ ភ្នំពេញ',
        owner: 'កក',
        size: 123,
      },
      construcion: {}
    }
  },
  computed: {
    ...mapGetters("imageOptionTab", {
      fetchDocuments: "fetchDocuments",
    }),
  },
  methods: {
    ...mapActions("imageOptionTab", {
      onTabDocument: "onTabDocument",
      resetFetchDocument: "resetFetchDocument",
    }),
    getProject() {
      this.$isLoading(this.isLoading);
      this.$axios
        .post(process.env.VUE_APP_API + "/api/backend/construction/show", {
          project_uuid: this.$route.params.project_uuid,
        })
        .then(({ data }) => {
          if (data.data) {
            this.project = data.data
            this.construcion = data.data.construction_information
          } else {
            this.project = null
          }
        })
        .catch((error) => {
          // this.onResponseError(error)
        })
        .finally(() => {
          this.isLoading = false;
          this.$isLoading(this.isLoading);
        });
    },
  },
  created() {
    this.getProject();
  },
  beforeDestroy() {
    this.resetFetchDocument();
  },
};
</script>

<style scoped></style>
