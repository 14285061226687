<template>
  <div class="row">
    <div class="col-md-12">
      <div class="nav-tabs-custom">
        <ul
          class="nav nav-tabs"
          role="tablist"
        >
          <li
            class="active"
            role="presentation"
          >
            <a
              aria-controls="project"
              data-toggle="tab"
              href="#project"
              role="tab"
            >{{ $t('tab.constructionTab') }}</a>
          </li>
          <template v-if="can([provinceProject.storeProcessRequesting])">
            <li role="presentation">
              <a
                aria-controls="request"
                data-toggle="tab"
                href="#request"
                role="tab"
              >{{ $t('string.processRequested') }}</a>
            </li>
          </template>
          <li
            role="presentation"
            @click="onTabDocument"
          >
            <a
              aria-controls="galleries"
              data-toggle="tab"
              href="#galleries"
              role="tab"
            >{{ $t('tab.attachDocument') }}</a>
          </li>
        </ul>

        <div class="tab-action">
          <router-link
            :to="{
              name: isProvinceDataLevel ? 'show-province-project' : 'show-project',
              params: {
                project_uuid: $route.params.project_uuid
              }
            }"
            class="btn btn-primary btn-sm"
          >
            <i class="fa fa-search" /> {{ $t('string.view') }}
          </router-link>
        </div>

        <div class="tab-content">
          <div
            id="project"
            class="tab-pane active"
            role="tabpanel"
          >
            <project-form
              :is-province-data-level="isProvinceDataLevel"
              :options="options"
            />
          </div>
          <template
            v-if="can([provinceProject.storeProcessRequesting])"
          >
            <div
              id="request"
              class="tab-pane"
              role="tabpanel"
            >
              <project-requesting-tab />
            </div>
          </template>
          <div
            id="galleries"
            class="tab-pane"
            role="tabpanel"
          >
            <template v-if="fetchDocuments">
              <upload-media
                :draggable="true"
                :model-uuid="$route.params.project_uuid"
                :button-label="$t('string.chooseFile')"
                model-name="project"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <direct-project-sync />
    </div>
  </div>
</template>

<script>
import ProjectForm from "./Form"
import UploadMedia from "../../components/UploadMedia"
import ProjectRequestingTab from "./includes/tabs/ProjectRequestingTab"
import DirectProjectSync from "./includes/components/DirectProjectSync"
import {mapActions, mapGetters} from "vuex"

export default {
  name: "ProjectEdit",
  components: {
    DirectProjectSync,
    UploadMedia,
    ProjectForm,
    ProjectRequestingTab,
  },
  metaInfo() {
    return {
      title: this.$t('string.editProject'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  props: {
    isProvinceDataLevel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: {
        title: this.$t('label.editProject'),
        is_province_data_level: false,
      }
    }
  },
  computed: {
    ...mapGetters('imageOptionTab', {
      fetchDocuments: 'fetchDocuments'
    }),
    provinceProject() {
      return this.$Permissions.backend.provinceProject
    }
  },
  methods: {
    ...mapActions('imageOptionTab', {
      onTabDocument: 'onTabDocument',
      resetFetchDocument: 'resetFetchDocument'
    })
  },
  beforeDestroy() {
    this.resetFetchDocument()
  }
}
</script>

<style scoped>
.nav-tabs-custom {
  position: relative;
}

.tab-action {
  position: absolute;
  right: 10px;
  top: 6px;
}
</style>
