import Primary from "../layouts/Primary"
import Permissions from "../permissions"
import {dashboardRoutes} from "./dashboard"
import {settingRouters} from "./settings"
import {accountRouters} from "./account"
import {administrationRouters} from "./admministrator"
import {projectRouters} from "./project"
import {missionRouters} from "./mission"
import {inspectionRouters} from "./inspections"
import {officeRouters} from "./office"
import {teamRouters} from "./team"
import {lawRegulationRouters} from "./lawRegulation"
import {noneBoardRouters} from "./noneBoard"
import {hasBoardRouters} from "./hasBoard"
import {investorConstructionRouters} from "./investorConstruction"
import {businessBuilderRouters} from "./businessBuilders"
import {provinceProjectRouters} from "./provinceProject"
import {unauthorizedRouters} from "./unauthorized"
import {officerRouters} from "./officer"
import {constructionLetterRouter} from "./constructionLetter"
import {certificatesRouters} from "./certificates"
import {companyManagementRouters} from "./companyManagement"
import {roubavontbokkolRouters} from "@/routers/roubavontbokkol"
import {certificatesProvinceRouters} from "@/routers/provinceCertificates"
import {newsRouters} from "@/routers/news"
import { constructionRouters } from "./construction"

export const primaryRouter = {
  path: '/admin',
  name: 'primary',
  component: Primary,
  redirect: '/admin/dashboard',
  meta: {
    permissions: [Permissions.backend.manage]
  },
  children: [
    dashboardRoutes,
    missionRouters,
    inspectionRouters,
    projectRouters,
    provinceProjectRouters,
    officeRouters,
    officerRouters,
    settingRouters,
    accountRouters,
    administrationRouters,
    teamRouters,
    lawRegulationRouters,
    newsRouters,
    noneBoardRouters,
    hasBoardRouters,
    investorConstructionRouters,
    businessBuilderRouters,
    unauthorizedRouters,
    constructionLetterRouter,
    certificatesRouters,
    companyManagementRouters,
    roubavontbokkolRouters,
    certificatesProvinceRouters,
    constructionRouters
  ]
}
