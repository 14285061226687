export const optionsMixin = {
  data() {
    return {
      projectSubTypes: [],
      project_type_id: 1,
    }
  },
  computed: {
    officerAllowPermits() {
      return {
        entry: 'លោក ភាគ សុធា',
        board_of_construction: 'ឯកឧត្តម ឆាន់ សូរ្យផល',
        board_of_study: 'លោក ឈៀក វីរៈ',
        castre: 'ឯកឧត្តម សួន សុផា',
        ministry_land: 'ឯកឧត្តម ឆាយ ឫទ្ធិសែន',
        permit: 'ឧបនាយករដ្ឋមន្រ្តី ជា សុផារ៉ា',
      }
    },
    constructionAccessoriesAbout() {
      return [
        {id: 1, name: this.$t('string.mainConcrete'), code: 'បេតុងអារមេ៉'},
        {id: 2, name: this.$t('string.steelConcrete'), code: 'បេតុងដែក'},
      ]
    },
    directions() {
      return [
        {id: 1, name_en: 'North', name_km: 'ឧត្តរ (ខាងជើង)'},
        {id: 5, name_en: 'North-East', name_km: 'ឦសាន (ខាងជើងឈៀងខាងកើត)'},
        {id: 2, name_en: 'East', name_km: 'បូព៌ (ខាងកើត)'},
        {id: 6, name_en: 'South-East', name_km: 'អាគ្នេយ៍ (ខាងត្បួងឈៀងខាងកើត)'},
        {id: 3, name_en: 'South', name_km: 'ទក្សិណ (ខាងត្បួង)'},
        {id: 7, name_en: 'South-West', name_km: 'និរតី (ខាងត្បួងឈៀងខាងលិច)'},
        {id: 4, name_en: 'West', name_km: 'បស្ចិម (ខាងលិច)'},
        {id: 8, name_en: 'North-West', name_km: 'ពាយ័ព្យ (ខាងលិចឈៀងខាងជើង)'},
      ]
    },

  },
  methods: {
    getConstructionAccessoriesAboutLabel(id) {
      let label = this.$t('string.na')
      if (id >= 1 && id <= this.constructionAccessoriesAbout.length) {
        let option = this.constructionAccessoriesAbout[id - 1]
        label = option['name']
      }
      return label
    },
    getDirectionLabel(id) {
      let label = this.$t('string.na')
      if (id >= 1 && id <= 8) {
        let direction = this.directions.find((item) => item.id === id)
        if (direction) {
          label = direction['name_' + this.$i18n.locale]
        }
      }
      return label
    },
    getProjectSubTypes(project_type_id) {
      this.$axios.post(`${process.env.VUE_APP_API}/api/backend/project-sub-type/get-project-sub-types`, {
        project_type_id: project_type_id,
      }).then(({data}) => {
        this.projectSubTypes = data.data
      })
    }
  },
}
