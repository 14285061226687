import './libraries/stylesheets'
import './libraries/scripts'

import Vue from 'vue'
import axios from './axios'
import App from './App.vue'
import getRouter from './router'
import store from './store/index'
import Meta from 'vue-meta'
import {i18n} from './locale'
import loading from 'vuejs-loading-screen'
import VueToastr from '@deveodk/vue-toastr'
import VueSweetalert2 from 'vue-sweetalert2'
import helpers from './plugins/helpers'
import strings from './plugins/strings'
import Multiselect from 'vue-multiselect'
import Permissions, {copyPermission} from "./permissions"
import DatePicker from 'vue2-datepicker'
import * as VueGoogleMaps from 'vue2-google-maps'
import Modal from "./components/Modal"
import Viewer from 'v-viewer'
import draggable from 'vuedraggable'
import HighchartsVue from 'highcharts-vue'
import VueMasonry from 'vue-masonry-css'
import VuePageTransition from 'vue-page-transition'
import Ripple from 'vue-ripple-directive'
import VueTheMask from 'vue-the-mask'
import VueSlider from 'vue-slider-component'
import PeopleChipItem from "./components/PeopleChipItem"
import AsoraBackButton from "./components/AsoraBackButton"
import VueNumeric from 'vue-numeric'
import PortalVue from 'portal-vue'
import VueQrcodeReader from 'vue-qrcode-reader'

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$base_api = process.env.VUE_APP_API
Vue.prototype.$token = process.env.VUE_APP_TOKEN
Vue.prototype.$Permissions = Permissions
Vue.use(Meta)
Vue.use(loading, {
  bg: '#2c2c2cc9'
})
Vue.use(helpers)
Vue.use(strings)
Vue.use(DatePicker)
Vue.use(Viewer)
Vue.use(draggable)
Vue.use(HighchartsVue)
Vue.use(VueMasonry)
Vue.use(VuePageTransition)
Vue.use(VueTheMask)
Vue.use(VueNumeric)
Vue.use(PortalVue)

Object.defineProperty(window, 'permission', {
  get: copyPermission
})
// configuration
Vue.use(VueToastr, {
  defaultPosition: 'toast-top-right',
  defaultType: 'info',
  defaultTimeout: 2000
})

Vue.use(VueSweetalert2, {
  confirmButtonColor: '#3c8dbc',
  cancelButtonColor: '#d33'
})

Vue.use(VueQrcodeReader)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCko7Leh71etR118fhDLyEVuaw4deIrOuw',
    libraries: 'places',
  },
  installComponents: true,
})

// momentjs
const moment = require('moment')
require('moment/locale/es')

Vue.use(require('vue-moment'), {
  moment
})

// register component
Vue.component('Multiselect', Multiselect)
Vue.component('Modal', Modal)
Vue.component('VueSlider', VueSlider)
Vue.component('PeopleChipItem', PeopleChipItem)
Vue.component('AsoraBackButton', AsoraBackButton)

// register ripple
Ripple.color = 'rgba(255, 255, 255, 0.35)'
Ripple.zIndex = 55
Vue.directive('ripple', Ripple)
Vue.directive('title', {
  inserted: (el, binding) => document.title = binding.value,
  update: (el, binding) => document.title = binding.value,
})

const vm = new Vue({
  router: getRouter(store),
  store,
  i18n,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')

export default vm
