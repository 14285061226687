<template>
  <div class="login-box">
    <div class="login-container">
      <div class="login-wrapper">
        <div class="login-logo">
          <div>
            <img
              src="/logo.png"
              class="brand-logo"
              alt="Logo"
            >
          </div>
          <h2 class="font-title text-black">
            ប្រព័ន្ធគ្រប់គ្រងសំណង់នៅកម្ពុជា
          </h2>
        </div>
        <div>
          <hr>
        </div>
        <div class="login-box-body">
          <div class="login-form">
            <div class="row">
              <p
                class="login-box-msg font-title"
                style="font-size: 22px;"
              >
                {{ $t('string.welcome_back') }}
              </p>
              <div
                v-if="messageError"
                class="col-md-12"
              >
                <div
                  class="alert alert-danger alert-dismissible"
                  role="alert"
                >
                  <button
                    aria-label="Close"
                    class="close"
                    data-dismiss="alert"
                    type="button"
                  >
                    <span
                      aria-hidden="true"
                    >&times;</span>
                  </button>
                  {{ messageError }}
                </div>
              </div>

              <div class="col-md-12">
                <div
                  class="form-group"
                  :class="{'has-error' : validations.email}"
                >
                  <input
                    v-model="email"
                    :placeholder="$t('label.email')"
                    class="form-control"
                    type="email"
                  >
                  <template v-if="validations.email">
                    <span class="label-error">{{ validations['email'][0] }}</span>
                  </template>
                </div>
              </div>

              <div class="col-md-12">
                <div
                  class="form-group"
                  :class="{'has-error' : validations.password}"
                >
                  <input
                    v-model="password"
                    :placeholder="$t('label.password')"
                    class="form-control"
                    type="password"
                  >
                  <template v-if="validations.password">
                    <span class="label-error">{{ validations['password'][0] }}</span>
                  </template>
                </div>
              </div>
              <div class="col-md-12 margin-bottom">
                <button
                  class="btn btn-primary btn-block capitalize btn-login slide"
                  @click="onClickLogin"
                >
                  <i class="fa fa-sign-in-alt" /> {{ $t('button.login') }}
                </button>
              </div>
              <div class="col-md-12 margin-top-15">
                <p class="text-center">
                  &copy; រក្សាសិទ្ធិដោយ <strong>អគ្គនាយកដ្ឋានសំណង់</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="label-developed">
        <p class="text-center">
          អភិវឌ្ឍន៍ដោយ <a
            href="https://asorasoft.com"
            target="_blank"
          >Asorasoft.com, {{ $t('string.version') }}
            {{ config.VUE_APP_VERSION }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  metaInfo() {
    return {
      title: this.$t('string.login'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      config: process.env,
      messageError: null,

      email: null,
      password: null,
      validations: {}
    }
  },
  mounted() {
    window.addEventListener('keypress', this.onPressEnter)
  },
  destroyed() {
    window.removeEventListener('keypress', this.onPressEnter)
  },
  methods: {
    onClickLogin() {
      this.validations = {}
      this.messageError = null

      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/auth/backend/login', {
        email: this.email,
        password: this.password
      }).then(async ({data}) => {
        if (data.data) {
          const {
            access_token,
            refresh_token,
            user,
            roles,
            permissions,
            provinces
          } = data.data

          localStorage.setItem(this.$token, access_token)
          localStorage.setItem(process.env.VUE_APP_REFRESH_TOKEN, refresh_token)

          this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
          this.$axios.defaults.headers.common['Accept'] = 'application/json'

          $.ajaxSetup({
            headers: {
              'Accept': 'application/json',
              'Authorization': "Bearer " + localStorage.getItem(this.$token),
            }
          })

          await this.$store.dispatch('user/setUser', {user})
          await this.$store.dispatch('user/setRoles', roles)
          await this.$store.commit('user/setPermissions', permissions)
          await this.$store.commit('user/setProvinces', provinces)

          await this.$router.push({
            name: 'admin',
            path: localStorage.getItem('fromRoutePath')
          })
        }
      }).catch((error) => {
        if (error.response.status === 401) {
          this.onResponseError(error)
        } else if (error.response.status === 422) {
          this.validations = error.response.data.errors
        } else {
          this.messageError = error.response.data
          if (this.messageError.message) {
            // eslint-disable-next-line no-prototype-builtins
            if (this.messageError.message.hasOwnProperty('message_en')) {
              this.messageError = this.messageError.message['message_' + this.$i18n.locale]
            }
          }
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    onPressEnter(e) {
      if (e.code === 'Enter') {
        this.onClickLogin()
      }
    },
  }
}
</script>

<style scoped>
.login-box {
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-wrapper {
  z-index: 9999;
  box-shadow: 0 10px 30px 5px #ddd;
  position: relative;
}

.login-box-body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-logo {
  color: #ffffff !important;
}

.login-form {
  width: 420px;
}

.brand-logo {
  width: 120px;
}

.font-title {
  font-family: KHMERMEF2, serif;
  font-size: 26px;
}

.login-logo {
  padding: 15px 15px 5px;
}

hr {
  border-color: #dddddd;
}

.label-developed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
